import { PayloadAction, createSlice, createAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { jwtDecode } from "jwt-decode";
import {
  createCompany,
  getAllCountries,
  getStateByCountryID,
  getUserByID,
  handleLogin,
  registerUser,
  resendRegisterOTP,
  updateCompanyInfo,
  updateUserProfile,
  userChangePassword,
  userForgotPassword,
  userRefreshToken,
  userResetPassword,
} from "./authAction";
import { LocationCredentials } from "./AuthTypes";

type AuthState = {
  isAuth: boolean;
  loading: boolean;
  token: string;
  refreshToken: string;
  steps: number;
  email: string;
  data: {
    user_id: number;
  };
  countries: { id: number; name: string }[];
  countriesLoading: boolean;
  state: { id: number; name: string }[];
  stateLoading: boolean;
  profile: {
    email: string;
    first_name: string;
    last_name: string;
    user_id: number;
    role: string;
    is_superuser: boolean;
    organization: {
      name: string;
      is_verified: boolean;
      id: number;
      locations: LocationCredentials[];
      services: [];
    };
  };
  companyInfo: {
    admin_id: number;
    organization_id: number;
  };
};

// Create a resetState action
export const resetState = createAction("RESET_STATE");

const initialState: AuthState = {
  isAuth: false,
  loading: false,
  token: "",
  refreshToken: "",
  steps: 1,
  email: "",
  data: {
    user_id: 0,
  },
  countries: [],
  countriesLoading: false,
  state: [],
  stateLoading: false,
  profile: {
    email: "",
    first_name: "",
    last_name: "",
    user_id: 0,
    role: "",
    is_superuser: false,
    organization: {
      name: "",
      is_verified: false,
      id: 0,
      locations: [],
      services: [],
    },
  },
  companyInfo: {
    admin_id: 0,
    organization_id: 0,
  },
};

// Configure Redux Persist
const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading", "countriesLoading", "stateLoading"],
  // Add any additional configuration options as needed
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isAuth = false;
      localStorage.setItem("token", "");
      localStorage.setItem("refreshToken", "");
      localStorage.clear();
      sessionStorage.clear();
    },
    logIn: (state, action: PayloadAction<string>) => {
      state.isAuth = true;
    },
    handleSteps: (state, action: PayloadAction<number>) => {
      state.steps = action.payload;
    },
    handleEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState); // Handle the global reset action

    // login user
    builder.addCase(handleLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      handleLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuth = true;
        localStorage.setItem("token", action.payload.data.access);
        localStorage.setItem("refreshToken", action.payload.data.refresh);
        state.token = action.payload.data.access;
        state.refreshToken = action.payload.data.refresh;
        const decodedUserInfo = jwtDecode<any>(action.payload.data.access);
        // console.log(decodedUserInfo);
        state.profile = decodedUserInfo;
        }
      );
      builder.addCase(handleLogin.rejected, (state) => {
        state.loading = false;
      });

        //refresh token
      builder.addCase(userRefreshToken.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(
        userRefreshToken.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.isAuth = true;
          localStorage.setItem("token", action.payload.data.access);
          state.token = action.payload.access;
          const decodedUserInfo = jwtDecode<any>(action.payload.data.access);
          state.profile = decodedUserInfo;  
        }
      );
      builder.addCase(userRefreshToken.rejected, (state) => {
        state.loading = false;
        state.isAuth = false;
      });

    // forgot password
    builder.addCase(userForgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userForgotPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userForgotPassword.rejected, (state) => {
      state.loading = false;
    });

    // user reset password
    builder.addCase(userResetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userResetPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userResetPassword.rejected, (state) => {
      state.loading = false;
    });

    // REGISTER USER
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      registerUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
      }
    );
    builder.addCase(registerUser.rejected, (state) => {
      state.loading = false;
    });

    // Resend Register OTP
    builder.addCase(resendRegisterOTP.pending, (state) => {});
    builder.addCase(
      resendRegisterOTP.fulfilled,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(resendRegisterOTP.rejected, (state) => {});

    // COUNTRIES
    builder.addCase(getAllCountries.pending, (state) => {
      state.countriesLoading = true;
    });
    builder.addCase(
      getAllCountries.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countriesLoading = false;
        state.countries = action.payload.data;
      }
    );
    builder.addCase(getAllCountries.rejected, (state) => {
      state.countriesLoading = false;
    });

    // STATES
    builder.addCase(getStateByCountryID.pending, (state) => {
      state.stateLoading = true;
    });
    builder.addCase(
      getStateByCountryID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.stateLoading = false;
        state.state = action.payload.data;
      }
    );
    builder.addCase(getStateByCountryID.rejected, (state) => {
      state.stateLoading = false;
    });

    // CREATE COMPANY
    builder.addCase(createCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createCompany.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;

        state.companyInfo = action.payload.data;
      }
    );
    builder.addCase(createCompany.rejected, (state) => {
      state.loading = false;
    });

    // Update Password
    builder.addCase(userChangePassword.pending, (state) => {});
    builder.addCase(
      userChangePassword.fulfilled,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(userChangePassword.rejected, (state) => {});

    // Update company information
    builder.addCase(updateCompanyInfo.pending, (state) => {});
    builder.addCase(
      updateCompanyInfo.fulfilled,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(updateCompanyInfo.rejected, (state) => {});

    // Update user information
    builder.addCase(updateUserProfile.pending, (state) => {});
    builder.addCase(
      updateUserProfile.fulfilled,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(updateUserProfile.rejected, (state) => {});

    // get User by ID
    builder.addCase(getUserByID.pending, (state) => {});
    builder.addCase(
      getUserByID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.profile.email = action.payload.data.user.email;
        state.profile.first_name = action.payload.data.user.first_name;
        state.profile.last_name = action.payload.data.user.last_name;
        state.profile.user_id = action.payload.data.user.id;
        state.profile.organization.name = action.payload.data.organization.name;
        state.profile.organization.id = action.payload.data.organization.id;
        state.profile.organization.is_verified =
          action.payload.data.organization.is_verified;
        state.profile.organization.locations =
          action.payload.data.organization.locations;
        state.profile.organization.services = action.payload.data.services;
      }
    );
    builder.addCase(getUserByID.rejected, (state) => {});
  },
});

const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
export const { logOut, logIn, handleSteps, handleEmail } = authSlice.actions;

export default authReducer;
