import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { handleSteps } from "../../../redux/Auth/Auth";
import GoBack from "../../../components/GoBack";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import close_icon from "../../../assets/close_icon.svg";
import {
  createCompany,
  getAllCountries,
  getStateByCountryID,
} from "../../../redux/Auth/authAction";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface countryType {
  id: number;
  name: string;
}

const StepFour = () => {
  const countryRef = useRef<HTMLDivElement | null>(null);
  const stateRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { steps, countries, state, data, countriesLoading, stateLoading } =
    useAppSelector((state) => state.auth);

  // const country = [
  //   {
  //     id: 1,
  //     name: "Nigeria",
  //   },
  //   {
  //     id: 2,
  //     name: "Ghana",
  //   },
  //   {
  //     id: 3,
  //     name: "Togo",
  //   },
  // ];

  // const state = [
  //   {
  //     id: 1,
  //     name: "Oyo",
  //   },
  //   {
  //     id: 2,
  //     name: "Ekiti",
  //   },
  //   {
  //     id: 3,
  //     name: "Osun",
  //   },
  // ];
  const [selectedCountry, setSelectedCountry] = useState<countryType>({
    id: 0,
    name: "",
  });
  const [selectedState, setSelectedState] = useState<countryType>({
    id: 0,
    name: "",
  });
  const [isToggleCountry, setIsToggleCountry] = useState(false);
  const [isToggleState, setIsToggleState] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [focusedSelect, setFocusedSelect] = useState<number | null>(null);
  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFocus = (index: number) => setFocusedSelect(index);
  const handleBlur = () => setFocusedSelect(null);

  const handleState = (id: number) => {
    const value = { id };
    dispatch(getStateByCountryID(value));
  };

  const handleCreateWorkspace = async () => {
    setLoading(true);
    const credentials = {
      user_id: data?.user_id,
      company_name: companyName,
      country: selectedCountry.id,
      state: selectedState.id,
      services: keywords,
    };
    const res = await dispatch(createCompany(credentials));
    if (res.type !== "createCompany/rejected") {
      setLoading(false);
      dispatch(handleSteps(1));
      navigate("/");
    } else {
      setLoading(false);
    }
  };

  const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setError("");
  };

  const handleDelete = (item: string) => {
    let resp = keywords.filter((i) => i !== item);
    setKeywords(resp);
  };

  const isValid = (keyword: string) => {
    let error = null;

    if (isInList(keyword)) {
      error = `${keyword} has already been added.`;
    }

    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const isInList = (val: string) => {
    return keywords.includes(val);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedKeyword = keyword.trim();

    if ((event.key === "Enter" || event.key === ",") && trimmedKeyword) {
      event.preventDefault();

      // Check if the keyword is already in the list
      if (trimmedKeyword && isValid(trimmedKeyword)) {
        // Add the new keyword to the keywords array
        setKeywords([...keywords, trimmedKeyword]);

        // Clear the input field
        setKeyword("");
      } else {
        // Optionally, handle duplicate keyword error here
        setError("This service already exists");
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      countryRef.current &&
      !countryRef.current.contains(event.target as Node)
    ) {
      setIsToggleCountry(false);
    }
    if (stateRef.current && !stateRef.current.contains(event.target as Node)) {
      setIsToggleState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);
  return (
    <div>
      {" "}
      <div className="">
        <div className="flex w-full justify-between items-center pb-[24px]">
          <GoBack
            handleClick={() => {
              dispatch(handleSteps(3));
            }}
          />
          <p className="text-[#3E473F] text-sm font-semibold leading-[18px]">
            {steps}/4
          </p>
        </div>
        <p className="text-[#181B19] text-[30px] font-semibold leading-[36px]">
          Set up your company <br /> workspace
        </p>
        <p className="text-disabled text-sm font-normal leading-5 pb-[18px] pt-1 w-[383px]">
          Tell us more about your company
        </p>

        <div className=" max-h-[500px] overflow-y-scroll sign_in_scroll">
          <div className="flex flex-col gap-5 pb-6">
            <div className="w-full">
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                Company Name
              </p>
              <input
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
                type="text"
                className="rounded-lg border w-full focus:border-primary focus:shadow-1xl  border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-medium leading-[18px]"
                placeholder="Company name"
              />
            </div>

            <div className="w-full" ref={countryRef}>
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                Country
              </p>

              <div
                tabIndex={1}
                onFocus={() => handleFocus(1)}
                onBlur={handleBlur}
                onClick={() => {
                  setIsToggleCountry((prev) => !prev);
                }}
                className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                  focusedSelect === 1
                    ? "focus:border-primary focus:shadow-1xl "
                    : ""
                }`}
              >
                <p className="text-sm font-medium leading-[18px] text-[#96A397]">
                  {selectedCountry?.name || "Select country"}
                </p>
                {isToggleCountry ? (
                  <ExpandLess style={{ color: "#96A397", fontSize: "20px" }} />
                ) : (
                  <ExpandMore style={{ color: "#96A397", fontSize: "20px" }} />
                )}
              </div>

              {isToggleCountry && (
                <div className="relative">
                  <div className="absolute top-1 left-1">
                    <div className="rounded-[10px] bg-white p-3 w-[348px] h-full overflow-scroll select_scroll max-h-[248px] shadow z-10">
                      {countriesLoading ? (
                        <div className="h-[100px] flex justify-center items-center">
                          <p className="text-sm font-medium leading-[18px]  ">
                            Loading...
                          </p>
                        </div>
                      ) : (
                        <>
                          {countries?.map((data, index) => {
                            return (
                              <div
                                className={`p-3 mb-[4px]  ${
                                  selectedCountry.name === data.name
                                    ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                    : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                }`}
                                onClick={() => {
                                  setSelectedCountry(data);
                                  handleState(data?.id);
                                  setIsToggleCountry(false);
                                }}
                                key={index}
                              >
                                <p className="text-sm font-medium leading-[18px] ">
                                  {data.name}
                                </p>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full" ref={stateRef}>
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                State/Province/District
              </p>

              <div
                tabIndex={2}
                onFocus={() => handleFocus(2)}
                onBlur={handleBlur}
                onClick={() => {
                  if (selectedCountry.name !== "") {
                    setIsToggleState((prev) => !prev);
                  } else {
                    toast.error("Select a country first", {
                      style: {
                        border: "1px solid #B92043",
                        backgroundColor: "#B92043",
                        color: "#FFFFFF",
                        fontSize: 14,
                        maxWidth: 400,
                      },
                      position: "top-left",
                    });
                  }
                }}
                className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-white border-[#96A397] ${
                  focusedSelect === 2
                    ? "focus:border-primary focus:shadow-1xl "
                    : ""
                }`}
              >
                <p className="text-sm font-medium leading-[18px] text-[#96A397]">
                  {selectedState?.name || "Select state/province"}
                </p>
                {isToggleState ? (
                  <ExpandLess style={{ color: "#96A397", fontSize: "20px" }} />
                ) : (
                  <ExpandMore style={{ color: "#96A397", fontSize: "20px" }} />
                )}
              </div>

              {isToggleState && (
                <div className="relative">
                  <div className="absolute top-1 left-1">
                    <div className="rounded-[10px] bg-white p-3 w-[348px] h-full overflow-scroll select_scroll max-h-[248px] shadow z-10">
                      {stateLoading ? (
                        <div className="h-[100px] flex justify-center items-center">
                          <p className="text-sm font-medium leading-[18px]  ">
                            Loading...
                          </p>
                        </div>
                      ) : (
                        <>
                          {" "}
                          {state?.map((data, index) => {
                            return (
                              <div
                                className={`p-3 mb-[4px]  ${
                                  selectedState.name === data.name
                                    ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                    : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                }`}
                                onClick={() => {
                                  setSelectedState(data);
                                  setIsToggleState(false);
                                }}
                                key={index}
                              >
                                <p className="text-sm font-medium leading-[18px] ">
                                  {data.name}
                                </p>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full">
              <label className={` text-[#344335] text-sm pb-1`}>
                Service(s) Offered
              </label>
              <div
                className={`rounded-lg border-[#96A397] bg-white border py-3 px-4 w-full appearance-none h-[134px] overflow-scroll select_scroll`}
              >
                <div className="flex flex-wrap items-center">
                  <div className="flex flex-wrap gap-2">
                    {keywords.length
                      ? keywords.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => handleDelete(item)}
                            className="flex justify-center last:mr-2  items-center bg-[#DBFFDF] gap-2 w-fit rounded-[4px] py-1 px-2 cursor-pointer"
                          >
                            <p className="text-[#2B8C34] text-sm font-medium">
                              {item}
                            </p>
                            <img
                              className=" w-[10px] h-[10px]"
                              src={close_icon}
                              alt="clear icon"
                            />
                          </div>
                        ))
                      : ""}
                  </div>
                  <input
                    className="appearance-none w-full flex-1 placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input bg-transparent"
                    type="text"
                    // disabled={disabled}
                    placeholder="Input the service(s) offered by your organisation"
                    value={keyword}
                    onChange={handleChangeKeyword}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
              <small
                className={
                  error
                    ? "text-[#B92043] font-medium text-xs"
                    : "text-[#5c715e] font-medium text-xs"
                }
              >
                {error
                  ? error
                  : "Use a comma(,) to separate different service(s)"}
              </small>
            </div>
          </div>
        </div>

        <button
          disabled={
            !loading &&
            companyName !== "" &&
            selectedCountry.name !== "" &&
            selectedState.name !== "" &&
            keywords.length !== 0
              ? false
              : true
          }
          onClick={handleCreateWorkspace}
          className="outline-none mb-[32px] w-full px-[32px] py-[16px] rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium leading-[18px]"
        >
          {loading ? "Loading..." : "Create Workspace"}
        </button>
      </div>
    </div>
  );
};

export default StepFour;
