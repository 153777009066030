import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  getFarmersInsightsTopics,
  getFarmersMonthlyStats,
  getFarmersStats,
} from "../../redux/Farmers/farmersAction";
import OverviewTable from "../../tables/Overview/Overview";
import OverviewChart from "../../components/Charts/Overview AreaChart/OverviewAreaChart";

const Dashboard = () => {
  const { farmersStats, insightsTopicTotal, monthlyStats } = useAppSelector(
    (state) => state.farmer
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getFarmersInsightsTopics({ search: "", page: 1 }));
    dispatch(getFarmersStats());
    dispatch(getFarmersMonthlyStats());
  }, [dispatch]);

  return (
    <div>
      <div>
        <Header title="Overview" />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
          <h4 className="text-[#2E332F] font-normal text-sm">Farmers</h4>

          <h4 className="text-[#2E332F] font-semibold text-xl">
            {farmersStats?.total_farmers?.count || 0}
          </h4>

          <p className=" text-sm font-medium">
            <span
              className={`${
                farmersStats?.total_farmers?.percentage_increase
                  ?.toString()
                  ?.startsWith("-")
                  ? "text-[#B92020]"
                  : "text-[#225928]"
              }`}
            >
              {" "}
              {farmersStats?.total_farmers?.percentage_increase?.toFixed(2) ||
                0}
              %
            </span>{" "}
            <span className="text-[#5F6D60] text-sm font-normal">
              vs last month
            </span>
          </p>
        </div>

        <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
          <h4 className="text-[#2E332F] font-normal text-sm">Calls</h4>

          <h4 className="text-[#2E332F] font-semibold text-xl">
            {farmersStats?.call_volume?.count || 0}
          </h4>
          <p className=" text-sm font-medium">
            <span
              className={`${
                farmersStats?.call_volume?.percentage_increase
                  ?.toString()
                  ?.startsWith("-")
                  ? "text-[#B92020]"
                  : "text-[#225928]"
              }`}
            >
              {" "}
              {farmersStats?.call_volume?.percentage_increase?.toFixed(2) || 0}%
            </span>{" "}
            <span className="text-[#5F6D60] text-sm font-normal">
              vs last month
            </span>
          </p>
        </div>

        <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
          <h4 className="text-[#2E332F] font-normal text-sm">
            Avg. call duration (min)
          </h4>

          <h4 className="text-[#2E332F] font-semibold text-xl">0</h4>

          <p className="text-[#225928] text-sm font-medium">
            30%{" "}
            <span className="text-[#5F6D60] text-sm font-normal">
              vs last month
            </span>
          </p>
        </div>
      </div>

      {/* <div className="mt-5 gap-4">
        <div className="bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg">
          <div className="p-4">
            <p className="text-[#2E332F] text-sm ">Farmers Uploaded</p>
            <div className="mt-3">
              <h4 className="text-primary font-semibold text-xl">0</h4>
            </div>
            <div className="mt-1">
              <p className="text-primary text-sm font-medium">
                {farmersStats?.new_farmers?.percentage_increase || 0}%{" "}
                <span className="text-[#5F6D60] text-sm font-normal">
                  vs last month
                </span>
              </p>
            </div>
          </div>

          <div className="mt-4 ml-[-20px] pb-5">
            <AreaChart data={overviewGraphData} height={300} />
          </div>
        </div>
      </div> */}

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4 min-h-[400px]">
        <>
          {/* <div className="grid grid-cols-4 gap-4">
          <div className="border border-[#E1E6E1] rounded-lg p-3 flex flex-col justify-between min-h-[130px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="bg-[#225928] w-[8px] h-[8px] rounded-full"></div>
                <div>
                  <p className="text-[#2E332F] text-sm">Calls</p>
                </div>
              </div>
              <div className="bg-[#E1E6E1] cursor-pointer w-[24px] h-[24px] flex justify-center items-center rounded-full">
                <NorthEast
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2E332F",
                  }}
                />
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="text-[#225928] font-semibold text-xl">32,342</h4>
              </div>
              <div className="mt-1">
                <p className="text-[#225928] text-sm font-medium">
                  30%{" "}
                  <span className="text-[#5F6D60] text-sm font-normal">
                    vs last month
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="border border-[#E1E6E1] rounded-lg p-3 flex flex-col justify-between min-h-[130px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="bg-[#3BAC46] w-[8px] h-[8px] rounded-full"></div>
                <div>
                  <p className="text-[#2E332F] text-sm">Returning Users</p>
                </div>
              </div>
              <div className="bg-[#E1E6E1] cursor-pointer w-[24px] h-[24px] flex justify-center items-center rounded-full">
                <NorthEast
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2E332F",
                  }}
                />
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="text-[#225928] font-semibold text-xl">
                  {returningUser || 0}
                </h4>
              </div>
              <div className="mt-1">
                <p className="text-primary text-sm font-medium">
                  {farmersStats?.returning_farmers?.percentage_increase || 0}%{" "}
                  <span className="text-[#5F6D60] text-sm font-normal">
                    vs last month
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="border border-[#E1E6E1] rounded-lg p-3 flex flex-col justify-between min-h-[130px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="bg-[#72B278] w-[8px] h-[8px] rounded-full"></div>
                <div>
                  <p className="text-[#2E332F] text-sm">First Time User</p>
                </div>
              </div>
              <div className="bg-[#E1E6E1] cursor-pointer w-[24px] h-[24px] flex justify-center items-center rounded-full">
                <NorthEast
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2E332F",
                  }}
                />
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="text-[#225928] font-semibold text-xl">
                  {" "}
                  {newusercount || 0}
                </h4>
              </div>
              <div className="mt-1">
                <p className="text-[#225928] text-sm font-medium">
                  30%{" "}
                  <span className="text-[#5F6D60] text-sm font-normal">
                    vs last month
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        </>

        <div className="grid grid-cols-4 gap-4">
          <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
            <div className="flex gap-2 items-center">
              <div className="w-2 h-2 bg-primary rounded-full" />
              <h4 className="text-[#2E332F] font-normal text-sm">
                Returning callers
              </h4>
            </div>

            <h4 className="text-[#2E332F] font-semibold text-xl">
              {" "}
              {farmersStats?.returning_farmers?.count || 0}
            </h4>

            <p className=" text-sm font-medium">
              <span
                className={`${
                  farmersStats?.returning_farmers?.percentage_increase
                    ?.toString()
                    ?.startsWith("-")
                    ? "text-[#B92020]"
                    : "text-[#225928]"
                }`}
              >
                {" "}
                {farmersStats?.returning_farmers?.percentage_increase?.toFixed(
                  2
                ) || 0}
                %
              </span>{" "}
              <span className="text-[#5F6D60] text-sm font-normal">
                vs last month
              </span>
            </p>
          </div>

          <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
            <div className="flex gap-2 items-center">
              <div className="w-2 h-2 bg-[#FFC508] rounded-full" />
              <h4 className="text-[#2E332F] font-normal text-sm">
                First time callers
              </h4>
            </div>

            <h4 className="text-[##2E332F] font-semibold text-xl">
              {" "}
              {farmersStats?.new_farmers?.count || 0}
            </h4>

            <p className=" text-sm font-medium">
              <span
                className={`${
                  farmersStats?.new_farmers?.percentage_increase
                    ?.toString()
                    ?.startsWith("-")
                    ? "text-[#B92020]"
                    : "text-[#225928]"
                }`}
              >
                {" "}
                {farmersStats?.new_farmers?.percentage_increase?.toFixed(2) ||
                  0}
                %
              </span>{" "}
              <span className="text-[#5F6D60] text-sm font-normal">
                vs last month
              </span>
            </p>
          </div>
        </div>

        <div className="mt-10">
          <OverviewChart data={monthlyStats} height={400} />
        </div>
      </div>

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4">
        <h4 className="text-[#2E332F] font-normal text-sm">Insight Topics</h4>

        <h4 className="text-[#2E332F] font-semibold text-xl pt-2">
          {insightsTopicTotal || 0}
        </h4>
        <OverviewTable />
      </div>
    </div>
  );
};

export default Dashboard;
