import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getFarmersByOrganization } from "../../redux/Farmers/farmersAction";
import dialog from "../../assets/Message Detail Placeholder Icon.svg";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search/search";
// import filter_icon from "../../assets/filter_icon.svg";
import { debounce } from "lodash";

const FarmersTable = () => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  // const [isFilter, setIsFilter] = useState(false);
  const { farmers, farmersLoading, farmersTotal } = useAppSelector(
    (state) => state.farmer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { profile } = useAppSelector((state) => state.auth);
  const [pageSize, setPageSize] = useState(10);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (page: number) => {
    setPageSize(page);
  };

  const farmersSearch = useMemo(
    () =>
      debounce((val: string) => {
        dispatch(
          getFarmersByOrganization({
            id: profile?.organization?.id,
            page: currentPage,
            search: val,
          })
        );
      }, 750),
    [dispatch, currentPage, profile?.organization?.id]
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      farmersSearch(e.target.value);
    },
    [farmersSearch]
  );

  useEffect(() => {
    dispatch(
      getFarmersByOrganization({
        id: profile?.organization?.id,
        page: currentPage,
      })
    );
  }, [dispatch, profile, currentPage]);

  return (
    <div>
      <h3 className="text-primary font-semibold text-base py-2">
        Farmers Onboarded
      </h3>
      <div className="flex flex-row gap-4 ">
        <div className="w-full">
          <SearchComponent
            placeholder="Search by Phone Number, First Name, Last Name"
            handleChange={(e) => handleSearch(e)}
            searchValue={searchValue}
          />
        </div>
        {/* <button
          onClick={() => setIsFilter(!isFilter)}
          className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer"
        >
          <img src={filter_icon} alt="filtericon" />
          <p>Filter</p>
        </button> */}
      </div>
      <div className="">
        {farmersLoading ? (
          <div className="animate-pulse w-full mt-4 pb-4 mb-4">
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
          </div>
        ) : (
          <>
            {farmers.length === 0 ? (
              <div className="flex flex-col min-h-[300px] justify-center items-center h-full w-full">
                <img
                  src={dialog}
                  alt="dialog icon"
                  className="w-[64px] h-[64px] pb-4"
                />
                <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                  Your farmers will all appear here when added.
                </p>
              </div>
            ) : (
              <>
                <div className="overflow-x-auto">
                  <table className="bg-white rounded-lg mt-4 w-full text-left p-4 mb-3">
                    <thead className="border bg-[#F6F7F6]  rounded-lg">
                      <tr className="grid grid-cols-6 gap-4 p-4">
                        <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                          First Name
                        </th>
                        <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                          Last Name
                        </th>
                        <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                          Gender
                        </th>
                        <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                          Country
                        </th>
                        <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                          State/Province
                        </th>
                        <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                          Phone Number
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {farmers?.map((farmer, index) => {
                        return (
                          <Link to={`/farmer-profile/${farmer?.id}`}>
                            <tr
                              key={index}
                              className="grid grid-cols-6 gap-4 p-4 hover:bg-[#E1F7E3] text-[#3e473f] text-sm font-medium leading-[18px] cursor-pointer"
                            >
                              <td className="">
                                {farmer?.firstName || "Nill"}
                              </td>
                              <td className="">{farmer?.lastName || "Nill"}</td>
                              <td className="">
                                {farmer?.gender === "M"
                                  ? "Male"
                                  : farmer?.gender === "F"
                                  ? "Female"
                                  : "Others"}
                              </td>
                              <td className="capitalize">
                                {" "}
                                {farmer?.country?.name || "Nill"}
                              </td>
                              <td className="capitalize">
                                {" "}
                                {farmer?.state?.name || "Nill"}
                              </td>
                              <td className="">
                                {farmer?.phoneNumber || "Nill"}
                              </td>
                            </tr>
                          </Link>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div>
                  <PaginationComponent
                    page={currentPage}
                    onPageChange={(page) => onPageChange(page)}
                    totalItems={farmersTotal}
                    pageSize={pageSize}
                    onPageSizeChange={(page) => onPageSizeChange(page)}
                    pageSizeShow
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FarmersTable;
