import React, { useEffect, useState } from "react";
import dialog from "../../assets/Message Detail Placeholder Icon.svg";
import GoBack from "../../components/GoBack";
import { Link, useNavigate } from "react-router-dom";
// import icon from "../../assets/Message Detail Placeholder Icon.svg";
import { ContentCopy, ForumOutlined } from "@mui/icons-material";
import SearchComponent from "../../components/Search/search";
import toast from "react-hot-toast";
import InsightsChart from "../../components/Charts/InsightsAreaChart copy/InsightsAreaChart";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  insightsConversation,
  insightsGraph,
} from "../../redux/Insights/insightsAction";
import { summaryType } from "../../redux/Insights/insights";

const CallInsightCategory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedCategory, setSelectedCategory] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: "",
  });
  const [isToggleShowMore, setIsToggleShowMore] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState<summaryType>({
    farmer: {
      id: 0,
      name: "",
      phone_number: "",
    },
    snippet: "",
    timestamp: {
      date: "",
      time: "",
    },
    conversation_details: [
      {
        sender: "",
        audio_url: "",
        timestamp: "",
        farmer_question: "",
      },
    ],
  });
  const {
    insight,
    graphData,
    graph,
    conversationSummaryLoading,
    conversationSummary,
    conversationSummaryTotal
  } = useAppSelector((state) => state.insights);

  const [conversationSumm, setConversationSumm] = useState<summaryType[]>([]);


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCopyNumber = (number: string) => {
    navigator.clipboard.writeText(number);
    toast.success(`${number} copied to clipboard`, {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-center",
    });
  };

  const handleLoadMore = () =>{
    setLoader(true)
    const nextPage = currentPage + 1;
    dispatch(insightsConversation({ id: insight?.id, page: nextPage })).then((res)=>{
      if(res?.type === "insightsConversation/fulfilled"){
        setLoader(false)
        const newConversations = res?.payload?.results;

        // Append new conversations to the existing list
        setConversationSumm((prevConversations) => [...prevConversations, ...newConversations])
        setCurrentPage(nextPage)
      }
      else if(res?.type === "insightsConversation/rejected"){
        setLoader(false)
      }
    });
  }

  useEffect(() => {
    // Sync local state with Redux state only when currentPage is 1
    if (currentPage === 1 && conversationSummary) {
      setConversationSumm(conversationSummary);
    }
  }, [conversationSummary, currentPage]);

  useEffect(() => {
    dispatch(insightsGraph({ id: insight?.id }));
    dispatch(insightsConversation({ id: insight?.id, page: 1 }));
    // setSelectedCategory({ id: insight?.id, name: insight?.name });
  }, [dispatch, insight]);


  return (
    <div>
      <div>
        <GoBack handleClick={() => navigate(-1)} />
      </div>

      <div>
        <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4 ">
          <div className="flex justify-between gap-1 items-center py-2 px-3 bg-[#F6F7F6] rounded-t-lg">
            {/* <img src={sense} alt="Sense Logo" className="" /> */}
            <p className="font-medium capitalize text-sm leading-[18px] text-[#000] ">
              {insight?.name?.replace("_", " ")}
            </p>

            <div className="flex gap-2 border border-[#E1E6E1]  px-1 py-2 bg-[#F7F7F7] rounded-lg cursor-pointer">
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                7D
              </p>
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                1M
              </p>
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                2M
              </p>
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                1Y
              </p>
            </div>
          </div>
          <div className="bg-[#E1E6E1] h-[1px]"></div>

          <div className="px-3 py-3 bg-white rounded-b-lg">
            <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
              Subcategories
            </p>

            <div className="flex flex-wrap gap-2 mt-3">
              {insight?.subtopics?.map((subTopic, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedCategory({
                        id: subTopic?.id,
                        name: subTopic?.name,
                      });
                      dispatch(insightsGraph({ id: subTopic?.id }));
                      dispatch(insightsConversation({ id: subTopic?.id }));
                    }}
                    key={index}
                    className={`border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer flex gap-1 ${
                      selectedCategory.name === subTopic?.name
                        ? "bg-primary text-white"
                        : "text-[#5F6D60]"
                    }`}
                  >
                    <p className=" text-xs font-medium leading-4 ">
                      {`${subTopic?.name} `}
                    </p>
                    <p className=" text-[11px] font-medium leading-4 ">{`( ${subTopic?.total_call_volume} )`}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* {!selectedCategory?.name && (
          <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4 ">
            <div className="flex justify-between gap-1 items-center py-3 px-3 bg-[#F6F7F6] rounded-t-lg">
              <p className="font-medium text-sm leading-[18px] text-[#000] ">
                Insight
              </p>
            </div>
            <div className="px-3 py-3 bg-white rounded-b-lg h-[400px] flex justify-center items-center">
              <div className="flex flex-col items-center gap-2">
                <div>
                  <img src={icon} alt="" className="" />
                </div>
                <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                  Select a subcategory to see more insight
                </p>
              </div>
            </div>
          </div>
        )} */}

        {/* {selectedCategory?.name !== "" && ( */}
        <div>
          <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4 ">
            <div className="flex justify-between gap-1 items-center py-3 px-3 bg-[#F6F7F6] rounded-t-lg">
              <p className="font-medium text-sm capitalize leading-[18px] text-[#000] ">
                {selectedCategory?.name || ""}
              </p>
            </div>
            <div className="bg-[#E1E6E1] h-[1px]"></div>
            <div className=" py-3 bg-white rounded-b-lg ">
              <div className="bg-[#FFFFFF]  rounded-lg py-4">
                <div className="px-4">
                  <p className="text-[#2E332F] text-sm ">Calls</p>
                  <div className="mt-3">
                    <h4 className="text-primary font-semibold text-xl">
                      {graphData?.current_month?.count || 0}
                    </h4>
                  </div>
                  <div className="mt-1">
                    <p className="text-primary text-sm font-medium">
                      {graphData?.current_month?.month_over_month_change || 0}%
                      <span className="text-[#5F6D60] text-sm font-normal">
                        vs last month
                      </span>
                    </p>
                  </div>
                </div>

                <div className="mt-10 pr-6">
                  <InsightsChart data={graph} height={300} />
                </div>

                <div className="mt-8 px-3">
                  <div className="flex border border-[#E1E6E1] rounded-lg w-full bg-white ">
                    <div className="w-[25%] border-r border-l-[#E1E6E1] ">
                      <div className="py-2 border-b border-b-[#E1E6E1] px-2 flex gap-1 items-center mb-2">
                        <ForumOutlined
                          style={{ color: "#2B8C34", fontSize: "22px" }}
                        />
                        <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                          Conversations Summary
                        </p>
                      </div>

                      <div className="pb-3 px-3">
                        <div className="w-full">
                          <SearchComponent
                            style={{ padding: "8px 40px" }}
                            placeholder="Search"
                            handleChange={handleSearch}
                            searchValue={searchValue}
                          />
                        </div>
                      </div>

                      {currentPage === 1 && conversationSummaryLoading ? (
                        <div className="h-[600px] flex mt-[40px] items-center flex-col">
                          <p className="text-sm">Loading...</p>
                        </div>
                      ) : (
                        <div className="flex flex-col overflow-y-auto cursor-pointer gap-2 h-[600px]">
                          {conversationSumm?.map((data, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  setSelectedSummary(data);
                                  setSelectedIndex(index);
                                }}
                                className={`p-4  ${
                                  selectedIndex === index ? "bg-[#E1F7E3]" : ""
                                } 
                                ${index !== conversationSumm.length - 1 ? "border-b border-[#E1E6E1" : ""}
                                ]`}
                              >
                                <div className="flex gap-2 cursor-pointer items-center pb-1">
                                  <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                                    {data?.farmer?.name}
                                  </p>
                                  <div className="bg-[#5C715E] w-[1px] h-[15px]"></div>
                                  <p className="text-[#4B4E68] font-normal text-sm leading-[18px]">
                                    {" "}
                                    {data?.farmer?.phone_number}
                                  </p>
                                </div>
                                <p className="font-normal text-sm leading-[20px] text-[#5F6D60] pb-[6px] truncate">
                                  {data?.snippet}
                                </p>
                                <p className="font-normal text-sm leading-[16px] text-[#5C715E]">
                                  {data?.timestamp?.time} |{" "}
                                  {data?.timestamp?.date}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {conversationSumm.length < conversationSummaryTotal &&
                      <div className="py-5 border-t border-[#E1E6E1] flex justify-center items-center">
                          <button
                          disabled={loader}
                          onClick={()=>{
                            handleLoadMore();
                            setCurrentPage(currentPage + 1)
                          }}
                            className="text-[#2B8C34] disabled:text-opacity-[0.6] font-medium text-sm">
                            {loader ? "Loading..." : "Load more"}
                           </button>
                      </div>
                      }
                    </div>

                    <div className="w-[75%] select_scroll">
                      {selectedSummary?.farmer?.name !== "" ? (
                        <>
                          <div className="flex pr-3 justify-between items-center">
                            <div className="flex items-center gap-[8px] px-3 py-0 pt-3">
                              {/* FARMER"S INITIALS */}
                              <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
                                <p className="font-medium text-base leading-6 text-white uppercase ">
                                  {selectedSummary?.farmer?.name?.charAt(0)}
                                </p>
                              </div>

                              <div className="">
                                <p className="text-[#344335] font-medium leading-[18px] text-sm">
                                  {selectedSummary?.farmer?.name}
                                </p>
                                <div
                                  className="flex gap-[6px] mt-[4px] cursor-pointer"
                                  onClick={() =>
                                    handleCopyNumber(
                                      selectedSummary?.farmer?.phone_number
                                    )
                                  }
                                >
                                  <p
                                    className={` text-[#4B4E68]
                  font-normal leading-[18px] text-sm`}
                                  >
                                    {selectedSummary?.farmer?.phone_number}
                                  </p>
                                  <ContentCopy
                                    className={` text-[#4B4E68]
                  `}
                                    style={{ fontSize: "18px" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <Link
                              to={`/farmer-profile/${selectedSummary?.farmer?.id}`}
                            >
                              <p className="text-primary cursor-pointer font-medium leading-[18px] text-sm">
                                View Profile
                              </p>
                            </Link>
                          </div>
                          <div className="border-b border-b-[#E1E6E1] flex flex-col gap-1 p-3 w-full px-4 ">
                            {/* <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                                Irrigation & Water Management
                              </p> */}
                            <p
                              className={`font-normal text-sm leading-[20px] text-[#5F6D60] ${
                                isToggleShowMore ? "" : "truncate"
                              }`}
                            >
                              {selectedSummary?.snippet}
                            </p>

                            <p
                              onClick={() =>
                                setIsToggleShowMore(!isToggleShowMore)
                              }
                              className="font-medium text-xs cursor-pointer leading-[16px] text-primary"
                            >
                              {isToggleShowMore ? "View less" : " Read more"}
                            </p>
                          </div>

                          <div className="flex flex-col gap-[40px] my-6 p-3 overflow-y-auto w-full scroll h-[500px]">
                            {selectedSummary?.conversation_details?.map(
                              (data, index) => {
                                return (
                                  <div>
                                    {data?.sender === "AI" ? (
                                      <div
                                        key={index}
                                        className="flex justify-end items-end"
                                      >
                                        <div className="rounded-lg border bg-[#F2FBF3] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                                          <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                            {data?.answer}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        key={index}
                                        className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] "
                                      >
                                        <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                          {data?.farmer_question}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {/* EMPTY STATE */}
                          <div className="flex flex-col justify-center items-center h-full w-full">
                            <img
                              src={dialog}
                              alt="dialog icon"
                              className="w-[64px] h-[64px] pb-3 "
                            />
                            <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                              Click on a summary to see the conversation
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default CallInsightCategory;
