import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { jwtDecode } from "jwt-decode";
import { userRefreshToken } from "../../redux/Auth/authAction";


interface route {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: route) => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const { exp } = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Get current time in seconds
        const timeUntilExpiration = exp ? exp - currentTime : 0 - currentTime; // Time remaining until token expiration in seconds
        const refreshThreshold = 300; // Refresh the token if it's about to expire within 5 minutes (300 seconds)
        if (timeUntilExpiration < refreshThreshold) {
          // Trigger token refresh
          dispatch(
            userRefreshToken({
              token: localStorage.getItem("refreshToken"),
            })
          );
        }
      }
    };

    // Set up interval to periodically check token expiration
    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <>
      {!isAuth ? (
        <Navigate to="/" replace state={{ path: location.pathname }} />
      ) : (
        children
      )}
    </>
  );
};

export default ProtectedRoute;
