import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Check, Visibility, VisibilityOff } from "@mui/icons-material";
// import Alert from "../../components/Alert";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userResetPassword } from "../../redux/Auth/authAction";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [isTogglePassword, setIsTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState("");

  const search = useLocation().search;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading } = useAppSelector((state) => state.auth);

  const handleResetPassword = () => {
    const otp: string | null = new URLSearchParams(search).get("otp");
    const id: string | null = new URLSearchParams(search).get("id");

    // console.log(id, otp);

    // setAlertMessage(
    //   "New password matches your old password. Please choose a different password."
    // );
    // Show the alert for 5 seconds
    // setShowAlert(true);
    // setTimeout(() => setShowAlert(false), 5000);
    dispatch(
      userResetPassword({
        user_id: Number(id),
        code: Number(otp),
        password: password,
      })
    ).then((res) => {
      if (res.type !== "userResetPassword/rejected") {
        navigate("/");
      }
    });
  };

  // useEffect(() => {
  //   // Hide alert after 5 seconds
  //   const timer = setTimeout(() => {
  //     setShowAlert(false);
  //   }, 5000);

  //   // Cleanup the timeout when component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <div className="w-full md:w-[383px]">
        {/* Alert display */}
        {/* <div className="mb-5">
          <Alert type="error" show={showAlert} message={alertMessage} />
        </div> */}

        <p className="text-[#181B19] text-3xl font-semibold">
          Set New Password
        </p>
        <p className="text-[#5F6D60] text-sm mt-3">
          Enter the new password you’d like for your account
        </p>

        <div className="mt-8">
          <div className="w-full">
            <p className="text-[#344335] text-sm font-medium pb-1">Password</p>
            <div className="relative">
              <input
                value={password}
                type={`${isTogglePassword ? "text" : "password"}`}
                onChange={(event) => setPassword(event?.target.value)}
                className="rounded-lg w-full focus:border-primary focus:shadow-1xl outline-none px-4 py-3 border bg-white border-[#96A397] bg-transparent text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                placeholder="Password"
              />
              <div className="cursor-pointer right-3 absolute top-1/2 transform -translate-y-1/2">
                {isTogglePassword ? (
                  <VisibilityOff
                    onClick={() => setIsTogglePassword(!isTogglePassword)}
                    style={{ color: "#96A397", fontSize: "18px" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setIsTogglePassword(!isTogglePassword)}
                    style={{ color: "#96A397", fontSize: "18px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="">
            <p className="font-medium text-xs text-[#5C715E] pb-1">
              Your Password Must Contain At Least:
            </p>

            <div className="flex flex-wrap gap-2">
              <div
                className={`${
                  password.length >= 8
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs font-medium`}
              >
                <p>Eight Characters</p>
                {password.length >= 8 && (
                  <Check
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
              {/* One Numeric Character */}
              <div
                className={`${
                  /[0-9]/g.test(password)
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>One Numeric Character</p>
                {/[0-9]/g.test(password) && (
                  <Check
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-wrap gap-2 mt-1 mb-6">
              {/* One Lowercase Character */}
              <div
                className={`${
                  /[a-z]/g.test(password)
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>One Lowercase Character</p>
                {/[a-z]/g.test(password) && (
                  <Check
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
              {/* One Uppercase Character */}
              <div
                className={`${
                  /[A-Z]/g.test(password)
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>One Uppercase Character</p>
                {/[A-Z]/g.test(password) && (
                  <Check
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <button
            onClick={handleResetPassword}
            disabled={loading || password.length < 8}
            className="outline-none w-full px-8 py-4 rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium"
          >
            {loading ? "Loading..." : "Reset Password"}
          </button>
        </div>

        <div className="flex gap-1 mt-5 justify-center items-center">
          <p className="text-[#5F6D60] text-sm font-medium">Back to Sign In?</p>
          <Link to="/" className="text-primary font-semibold text-sm">
            Sign In
          </Link>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
