import { ChevronLeft } from "@mui/icons-material";
import React from "react";

interface GoBackType {
  handleClick: () => void;
}

const GoBack: React.FC<GoBackType> = ({ handleClick }: GoBackType) => {
  return (
    <div
      className="flex gap-2 items-center cursor-pointer"
      onClick={handleClick}
    >
      <div className="w-5 h-5 border border-disabled bg-[#E1E6E1] flex items-center justify-center rounded-full">
        <ChevronLeft style={{ color: "#3E473F", fontSize: "16px" }} />
      </div>
      <p className="text-[#3E473F] text-sm font-semibold leading-[18px]">
        Go Back
      </p>
    </div>
  );
};

export default GoBack;
