import React,{useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import GoBack from "../../../components/GoBack";
import VerificationInput from "react-verification-input";
import { handleSteps } from "../../../redux/Auth/Auth";
import { useState } from "react";
import { confirmationCode, resendRegisterOTP } from "../../../redux/Auth/authAction";

const StepTwo = () => {
  const dispatch = useAppDispatch();
  const { steps, email, data } = useAppSelector((state) => state.auth);
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

     // Format minutes and seconds with leading zeros
     const formattedTime = `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

  const handleInputChange = (value: string) => {
    setVerificationCode(value);
  };

  const handleStepTwo = async () => {
    setLoading(true);
    const credentials = {
      email: email,
      code: verificationCode,
    };
    const res = await dispatch(confirmationCode(credentials));
    if (res.type !== "confirmationCode/rejected") {
      dispatch(handleSteps(3));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleResendOTP = () =>{
    setOtpLoader(true)
    dispatch(resendRegisterOTP({
      user_id: data.user_id
    })).then((res)=>{
      if(res?.type === "resendRegisterOTP/fulfilled"){
        setOtpLoader(false)
      }
      else if(res?.type === "resendRegisterOTP/rejected"){
        setOtpLoader(false)
      }
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval);
        // Handle timer expiration here
      } else if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds])

  return (
    <div>
      {" "}
      <div>
        <div className="flex w-full justify-between items-center pb-[24px]">
          <GoBack
            handleClick={() => {
              dispatch(handleSteps(1));
            }}
          />
          <p className="text-[#3E473F] text-sm font-semibold leading-[18px]">
            {steps}/4
          </p>
        </div>
        <p className="text-[#181B19] text-[30px] font-semibold leading-[36px]">
          Verify your account
        </p>

        <p className="text-[#5F6D60] text-[16px] font-normal leading-5 mb-[32px] pt-3">
          We’ve sent a 6 digit code to your email address <br />{" "}
          <span className="text-[#181B19] font-semibold">{email ? email : 'name@company.com'}</span>
        </p>

        <div className="mb-5">
          <VerificationInput
            validChars="0-9"
            placeholder="0"
            classNames={{
              container: "verification-container",
              character: "verification-character",
              characterInactive: "character--inactive",
              characterSelected: "verification-character--selected",
              characterFilled: "character--filled",
            }}
            value={verificationCode}
            onChange={handleInputChange}
            inputProps={{ inputMode: "numeric" }}
          />
        </div>

        <div className="">
        {
          minutes === 0 && seconds === 0 
              ?
              <div className='flex gap-1 items-center justify-center'>
                  <button disabled={otpLoader} onClick={handleResendOTP} className='text-[#2B8C34] disabled:text-opacity-[0.6] text-sm font-medium'>Resend code</button>
                  <p className="text-[#5F6D60] text-sm font-medium">in 0:00</p>
              </div>
              :
          <div className='flex justify-center'>
              <p className='text-[#5F6D60] text-sm font-medium'>Resend code in <span className="text-[#181B19]">{formattedTime}</span></p>
          </div>
          }
        </div>

        <button
          disabled={verificationCode.length !== 6 || loading ? true : false}
          onClick={
            verificationCode.length !== 6 || loading ? () => {} : handleStepTwo
          }
          className="outline-none mb-[32px] mt-6 w-full px-[32px] py-[16px] rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium leading-[18px]"
        >
          {loading ? "Loading..." : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
