import React, { ReactNode } from "react";

interface HeaderType {
  title?: string;
  btn?: ReactNode;
  children?: ReactNode;
}
const Header = ({ title, children, btn }: HeaderType) => {
  return (
    <div className="w-full my-4">
      <div className="flex flex-col p-4 bg-white border border-[#E1E6E1] w-full rounded-lg">
        <div className="flex justify-between items-center w-full">
          <p className="font-medium text-lg leading-6 text-[#292621] py-1">
            {title}
          </p>
          <div>{btn}</div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Header;
