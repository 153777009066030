import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import {
  AutoAwesomeSharp,
  ExpandLess,
  ExpandMore,
  Logout,
  SettingsOutlined,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

import farmer from "../../assets/farmer.svg";
import farmer_active from "../../assets/farmer_active.svg";
import dashbaord from "../../assets/dashboard.svg";
import dashbaord_active from "../../assets/dashboard_active.svg";
import { useDispatch, useSelector } from "react-redux";
import { logOut, resetState } from "../../redux/Auth/Auth";
// import referrals from "../../assets/referrals.svg";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [toggleSelect, setToggleSelect] = useState(false);
  const { profile } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logOut());
    dispatch(resetState())
  };

  return (
    <div className="flex bg-[#F6F7F6] h-screen">
      <div className="md:w-[256px] bg-white border rounded-lg border-[#ECEFEC] flex-shrink-0 overflow-y-auto pt-[30px] h-screen sidebar_scroll px-3">
        <img src={logo} alt="logo" className="  overflow-y-scroll" />

        <div className="my-6 relative">
          <div
            onClick={() => setToggleSelect(!toggleSelect)}
            className="w-full h-[50px] border border-[#DDE2DE] rounded-lg flex gap-3 justify-between items-center p-2 cursor-pointer"
          >
            <div className="flex gap-[6px] items-center ">
              <div className="w-[32px] h-[32px] rounded-full bg-primary flex justify-center items-center">
                <p className="font-semibold text-xs text-white">
                  {profile?.first_name?.charAt(0)}
                  {profile?.last_name?.charAt(0)}
                </p>
              </div>
              <div className="flex gap-[2px] flex-col w-[150px]">
                <p className="font-semibold text-xs text-[#161D17] trunacte">
                  {profile?.first_name} {profile?.last_name}
                </p>
                <p className="text-[#5C715E] font-medium text-xs truncate">
                  {profile?.email}
                </p>
              </div>
            </div>
            <div className="">
              {toggleSelect ? (
                <ExpandLess style={{ color: "#161D17", fontSize: "18px" }} />
              ) : (
                <ExpandMore style={{ color: "#161D17", fontSize: "18px" }} />
              )}
            </div>
          </div>

          {toggleSelect && (
            <div
              onClick={handleLogout}
              className="bg-white absolute w-full rounded-lg py-3  px-[12px] flex gap-2 items-center auth_shadow mt-1 cursor-pointer border border-[#EDECE7]"
            >
              <Logout style={{ color: "#3E473F", fontSize: "18px" }} />
              <p className="text-sm font-medium leading-[18px] opacity-[0.9] text-[#3E473F]">
                Log out
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-1 cursor-pointer">
          {/* OVERVIEW */}
          <Link to="/overview">
            <div
              className={`flex gap-2 items-center rounded-lg p-3 hover:bg-[#EDF7EE] ${
                location.pathname === "/overview" ? "bg-[#E1F7E3]" : ""
              } `}
            >
              {location.pathname === "/overview" ? (
                <div>
                  <img src={dashbaord_active} alt="dashboard" className="" />
                </div>
              ) : (
                <div>
                  <img src={dashbaord} alt="dashboard" className="" />
                </div>
              )}

              <p
                className={`${
                  location.pathname === "/overview"
                    ? "text-[#161D17] font-medium"
                    : "text-[#5C715E] font-normal"
                }  text-sm leading-[18px] `}
              >
                Overview
              </p>
            </div>
          </Link>

          {/* Farmers*/}
          <Link to="/farmers">
            <div
              className={`flex gap-2 items-center rounded-lg p-3 hover:bg-[#EDF7EE] ${
                location.pathname === "/farmers" ||
                location.pathname.includes("farmer-profile")
                  ? "bg-[#E1F7E3]"
                  : ""
              } `}
            >
              {location.pathname === "/farmers" ||
              location.pathname.includes("farmer-profile") ? (
                <div>
                  <img src={farmer_active} alt="farmer" />
                </div>
              ) : (
                <div>
                  <img src={farmer} alt="farmer" />
                </div>
              )}

              <p
                className={`${
                  location.pathname === "/farmers" ||
                  location.pathname.includes("farmer-profile")
                    ? "text-[#161D17] font-medium"
                    : "text-[#5C715E] font-normal"
                }  text-sm leading-[18px] `}
              >
                Farmers
              </p>
            </div>
          </Link>

          {/* Knowledgebase */}
          {/* <Link to="/referrals">
            <div
              className={`flex gap-2 items-center rounded-lg p-3 hover:bg-[#EDF7EE] ${
                location.pathname === "/referrals" ? "bg-[#E1F7E3]" : ""
              } `}
            >
              {location.pathname === "/referrals" ? (
                <div>
                  <img src={referrals} alt="referrals" />
                </div>
              ) : (
                <div>
                  <img src={referrals} alt="referrals" />
                </div>
              )}
              <p
                className={`${
                  location.pathname === "/referrals"
                    ? "text-[#161D17] font-medium"
                    : "text-[#5C715E] font-normal"
                }  text-sm leading-[18px] `}
              >
                Referrals
              </p>
            </div>
          </Link> */}

          {/* Chatbot */}
          <Link to="/call-insights">
            <div
              className={`flex gap-2 items-center rounded-lg p-3 hover:bg-[#EDF7EE] ${
                location.pathname.includes("call-insights")
                  ? "bg-[#E1F7E3]"
                  : ""
              } `}
            >
              {location.pathname.includes("call-insights") ? (
                <AutoAwesomeSharp
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#2B8C34",
                  }}
                />
              ) : (
                <AutoAwesomeSharp
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#5C715E",
                  }}
                />
              )}
              <p
                className={`${
                  location.pathname.includes("call-insights")
                    ? "text-[#161D17] font-medium"
                    : "text-[#5C715E] font-normal"
                }  text-sm leading-[18px] `}
              >
                Call Insights
              </p>
            </div>
          </Link>

          {/* Settings */}
          <Link to="/settings">
            <div
              className={`flex gap-2 items-center rounded-lg p-3 hover:bg-[#EDF7EE] ${
                location.pathname === "/settings" ? "bg-[#E1F7E3]" : ""
              } `}
            >
              {location.pathname === "/settings" ? (
                <SettingsOutlined
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#2B8C34",
                  }}
                />
              ) : (
                <SettingsOutlined
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#5C715E",
                  }}
                />
              )}
              <p
                className={`${
                  location.pathname === "/settings"
                    ? "text-[#161D17] font-medium"
                    : "text-[#5C715E] font-normal"
                }  text-sm leading-[18px] `}
              >
                Settings
              </p>
            </div>
          </Link>
        </div>
      </div>

      <div className="py-3 flex-grow flex-auto flex-shrink overflow-y-scroll">
        {/* NAV BAR */}
        {/* <div className="flex px-4 justify-between items-center">
          <div>
            <p className='text-[#344335] text-sm font-semibold'>{title}</p>
          </div>
          <div className="flex gap-4 items-center">
            <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full relative">
              <img src={notification_icon} alt="notification" />
              <div className="bg-[#B92043] w-[10px] h-[10px] rounded-full absolute -right-1 top-1 border-white border-2"></div>
            </div>
            <div className="bg-[#2B8C34] flex justify-center items-center w-[32px] h-[32px] rounded-full">
              <p className="text-white text-xs font-medium">
                IB
              </p>
            </div>
            <div
              className="flex items-center gap-2 cursor-pointer relative"
              onClick={toggleNavMenu}
            >
              <p className="text-[#5C715E] text-sm font-medium">
                Isaac Bluman
              </p>
              <img
                src={isNavMenu ? chevron_up : chevron_down}
                alt="chevron arrow"
              />

              <ul
                className={
                  isNavMenu
                    ? "absolute top-10 transition-all right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop z-10"
                    : "absolute hidden transition-all top-20 right-0 bg-[#fff] rounded-[10px] min-w-[162px] border shadow-drop"
                }
              >
                <Link
                  to="/profile"
                  className="px-6 py-3 hover:bg-[#EDF7EE] flex hover:rounded-tr-[10px] hover:rounded-tl-[10px] transition-all"
                >
                  <p className="text-[#344335] text-sm font-medium">
                    View Profile
                  </p>
                </Link>
                <li
                  className="px-6 py-3 hover:bg-[#EDF7EE] hover:rounded-bl-[10px] hover:rounded-br-[10px] transition-all flex item-center gap-2"
                >
                  <img src={logout_icon} alt="logout" />
                  <p className="text-[#344335] text-sm font-medium">Log Out</p>
                </li>
              </ul>
            </div>
          </div>
          </div> */}
        <div className="px-4 w-full overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
