import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  addFarmer,
  getFarmersByOrganization,
  getFarmersInsightsTopics,
  getFarmersMonthlyStats,
  getFarmersProfile,
  getFarmersStats,
  getNewuserCount,
  getOverviewGraph,
  getReturningUsers,
  uploadFarmers,
} from "./farmersAction";
import { resetState } from "../Auth/Auth";
import { farmerProfileType } from "./farmersTypes";

type FarmerState = {
  loading: boolean;
  overviewGraphData: {
    month: string;
    farmer_count: number;
  }[];
  returningUser: string;
  newusercount: string;
  farmersStats: {
    new_farmers: {
      count: number;
      percentage_increase: number;
    };
    returning_farmers: {
      count: number;
      percentage_increase: number;
    };
    total_farmers: {
      count: number;
      percentage_increase: number;
    };
    call_volume: {
      count: number;
      percentage_increase: number;
    };
  };
  progress: number;
  farmers: {
    id: string;
    created: string;
    updated: string;
    firstName: string;
    lastName: string;
    age: number;
    phoneNumber: string;
    gender: string;
    language: string;
    organizationIdId: string;
    country: {
      createdAt: string;
      id: string;
      name: string;
      updatedAt: string;
    };
    state: {
      createdAt: string;
      id: string;
      name: string;
      updatedAt: string;
    };
  }[];
  farmersTotal: number;
  farmersLoading: boolean;
  farmerProfile: farmerProfileType;
  monthlyStats: {
    month: string;
    new_farmers: number;
    returning_farmers: number;
  }[];
  insightsTopic: {
    id: number;
    name: string;
    description: string;
    total_call_volume: number;
    subtopics: {
      id: number;
      name: string;
      total_call_volume: number;
    }[];
  }[];
  insightsTopicLoading: boolean;
  insightsTopicTotal: number;
};

type OverviewFarmerData = {
  month: string;
  new_farmers: number;
  returning_farmers: number;
};

type FarmerData = {
  month: string;
  farmer_count: number;
};

const initialState: FarmerState = {
  loading: false,
  overviewGraphData: [],
  returningUser: "",
  newusercount: "",
  progress: 0,
  farmersStats: {
    new_farmers: {
      count: 0,
      percentage_increase: 0,
    },
    returning_farmers: {
      count: 0,
      percentage_increase: 0,
    },
    total_farmers: {
      count: 0,
      percentage_increase: 0,
    },
    call_volume: {
      count: 0,
      percentage_increase: 0,
    },
  },
  farmers: [],
  farmersTotal: 0,
  farmersLoading: false,
  farmerProfile: {
    first_name: "",
    last_name: "",
    phone_number: "",
    age: 0,
    crop: "",
    country: "",
    location: "",
    language: "",
    gender: "O",
    livestock: "",
    state: "",
    district: "",
    dob: "",
    farm_characteristics: {
      location: "",
      farm_size: "",
      current_crops: "",
      soil_type: "",
      soil_ph: "",
      irrigation_type: "",
      water_source: "",
      storage_capacity: "",
      equipment: "",
      market_channels: "",
      certifications: "",
      years_farming: "",
      technical_expertise: "",
      current_issues: "",
    },
    agricultural_practices: {
      cultivation_techniques: "",
      irrigation_methods: "",
      pest_management_approaches: "",
      soil_management_practices: "",
      post_harvest_practice: "",
    },
    technical_parameters: {
      soil_type: "",
      conditions: {
        ph_level: "",
      },
      water_sources_and_availability: "",
      growing_season: {
        duration: "",
      },
      yield_data: {
        maize: "",
        cassava: "",
        groundnuts: "",
      },
      chemical_fertilizer_usage: "",
    },
    knowledge_and_experience: {
      years_farming_experience: "",
      technical_expertise_areas: [],
      training_education_background: {
        level: "",
      },
      technology_adoption_level: {
        description: "",
      },
      previous_crop_history: {
        values: [],
      },
    },
    business_context: {
      market_connections: {
        values: [],
      },
      distribution_channels: {
        values: [],
      },
      processing_capabilities: {
        values: "",
      },
      storage_facilities: {
        values: "",
      },
      certifications_held: {
        values: [],
      },
    },
  },
  monthlyStats: [],
  insightsTopic: [
    {
      id: 0,
      name: "",
      description: "",
      total_call_volume: 0,
      subtopics: [],
    },
  ],
  insightsTopicLoading: false,
  insightsTopicTotal: 0,
};

// Configure Redux Persist
const farmerPersistConfig = {
  key: "farmer",
  storage,
  blacklist: ["loading", "progress", "farmerProfile", "farmersLoading"],
  // Add any additional configuration options as needed
};

export const farmerSlice = createSlice({
  name: "farmer",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },

    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);

    // upload farmers
    builder.addCase(uploadFarmers.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(
      uploadFarmers.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state.loading = false;
      }
    );
    builder.addCase(
      uploadFarmers.rejected,
      (state, action: PayloadAction<any>) => {
        // state.loading = false;
      }
    );

    // add farmers
    builder.addCase(addFarmer.pending, (state) => {});
    builder.addCase(
      addFarmer.fulfilled,
      (state, action: PayloadAction<any>) => {}
    );
    builder.addCase(
      addFarmer.rejected,
      (state, action: PayloadAction<any>) => {}
    );

    // overview data
    builder.addCase(getOverviewGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getOverviewGraph.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload?.data;
        const formatMonth = (monthString: string): string => {
          const [year, month] = monthString.split("-");
          const monthMap: { [key: string]: string } = {
            January: "Jan",
            February: "Feb",
            March: "Mar",
            April: "Apr",
            May: "May",
            June: "Jun",
            July: "Jul",
            August: "Aug",
            September: "Sep",
            October: "Oct",
            November: "Nov",
            December: "Dec",
          };
          return `${monthMap[month]}(${year})`;
        };

        const formattedData: FarmerData[] = payloadData?.map((item: any) => ({
          month: formatMonth(item.month),
          farmer_count: item.farmer_count,
        }));

        state.overviewGraphData = formattedData;
      }
    );
    builder.addCase(
      getOverviewGraph.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // returning user
    builder.addCase(getReturningUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getReturningUsers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload?.returning_farmers;

        state.returningUser = payloadData;
      }
    );
    builder.addCase(
      getReturningUsers.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // new user count
    builder.addCase(getNewuserCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getNewuserCount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload?.new_farmers;

        state.newusercount = payloadData;
      }
    );
    builder.addCase(
      getNewuserCount.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // farmers stats
    builder.addCase(getFarmersStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getFarmersStats.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload;

        state.farmersStats = payloadData;
      }
    );
    builder.addCase(
      getFarmersStats.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // get all farmers by organization
    builder.addCase(getFarmersByOrganization.pending, (state) => {
      state.farmersLoading = true;
    });
    builder.addCase(
      getFarmersByOrganization.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.farmersLoading = false;
        const payloadData = action.payload;
        state.farmers = payloadData.data;
        state.farmersTotal = payloadData.meta.total;
      }
    );
    builder.addCase(
      getFarmersByOrganization.rejected,
      (state, action: PayloadAction<any>) => {
        state.farmersLoading = false;
      }
    );

    // get farmers profile by org id
    builder.addCase(getFarmersProfile.pending, (state) => {
      state.farmersLoading = true;
    });
    builder.addCase(
      getFarmersProfile.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.farmerProfile = action.payload;
        state.farmersLoading = false;
      }
    );
    builder.addCase(
      getFarmersProfile.rejected,
      (state, action: PayloadAction<any>) => {
        state.farmersLoading = false;
      }
    );

    // get farmers monthly stats
    builder.addCase(getFarmersMonthlyStats.pending, (state) => {});
    builder.addCase(
      getFarmersMonthlyStats.fulfilled,
      (state, action: PayloadAction<any>) => {
        const payloadData = action.payload;
        const formatMonth = (monthString: string): string => {
          const [year, month] = monthString.split("-");
          const monthMap: { [key: string]: string } = {
            January: "Jan",
            February: "Feb",
            March: "Mar",
            April: "Apr",
            May: "May",
            June: "Jun",
            July: "Jul",
            August: "Aug",
            September: "Sep",
            October: "Oct",
            November: "Nov",
            December: "Dec",
          };
          return `${monthMap[month]}(${year})`;
        };

        const formattedData: OverviewFarmerData[] = payloadData?.data?.map(
          (item: any) => ({
            month: formatMonth(item.month),
            new_farmers: item.new_farmers,
            returning_farmers: item.returning_farmers,
          })
        );

        state.monthlyStats = formattedData;
      }
    );
    builder.addCase(
      getFarmersMonthlyStats.rejected,
      (state, action: PayloadAction<any>) => {}
    );

    // get insights topics
    builder.addCase(getFarmersInsightsTopics.pending, (state) => {
      state.insightsTopicLoading = true;
    });
    builder.addCase(
      getFarmersInsightsTopics.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.insightsTopicLoading = false;
        const payloadData = action.payload;
        state.insightsTopicTotal = payloadData.count;
        state.insightsTopic = payloadData.results;
      }
    );
    builder.addCase(
      getFarmersInsightsTopics.rejected,
      (state, action: PayloadAction<any>) => {
        state.insightsTopicLoading = false;
      }
    );
  },
});

const farmerReducer = persistReducer(farmerPersistConfig, farmerSlice.reducer);
export const { logOut, setProgress } = farmerSlice.actions;

export default farmerReducer;
