import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/store";

interface AuthLayoutType {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutType> = ({ children }: AuthLayoutType) => {
  const location = useLocation();
  const { steps } = useAppSelector((state) => state.auth);

  return (
    <div className="h-full">
      <div className="flex flex-col md:flex-row gap-3 justify-center md:justify-between p-4 h-screen">
        <div className="w-full relative flex flex-col justify-center md:w-1/2">
          <div className="relative max-w-sm mx-auto">
            <div className="flex justify-center items-center h-full">
              <div>{children}</div>
            </div>
          </div>
          {(location.pathname === "/" ||
            (steps === 1 && location.pathname === "/sign-up")) && (
            <div className="absolute bottom-4 w-full">
              <div className="flex items-end justify-center w-full">
                <p className="text-center font-medium text-sm leading-[18px] max-w-[273px]">
                  <span className="text-disabled">
                    By signing up you have agreed to our
                  </span>{" "}
                  Terms of Service and Privacy Policy
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="hidden md:flex w-1/2 ">
          <div
            className={`rounded-lg bg-[#C4CBC5] relative w-full h-full  ${
              location.pathname === "/verify-account"
                ? "verify-account_background "
                : (location.pathname === "/sign-up" && steps === 1) ||
                  location.pathname === "/"
                ? "sign-in_background "
                : location.pathname === "/sign-up" && steps === 2
                ? "sign-up_background_step_2 "
                : location.pathname === "/sign-up" && steps === 3
                ? "sign-up_background_step_3"
                : location.pathname === "/sign-up" && steps === 4
                ? "sign-up_background_step_4"
                : "reset-passport_background "
            }`}
          >
            <div
              className={`${
                location.pathname === "/reset-password"
                  ? "background-reset rounded-b-lg px-5 w-full h-full absolute bottom-[0px]"
                  : location.pathname === "/sign-up" && steps === 4
                  ? "background rounded-lg px-5 pt-[10%]"
                  : "background rounded-lg px-5 pt-[25%]"
              }`}
            >
              <div
                className={`${
                  location.pathname === "/reset-password"
                    ? "absolute bottom-[25%]"
                    : ""
                }`}
              >
                <p className="font-semibold text-[32px] leading-[24px] text-[#FFFFFF] py-1">
                  {location.pathname === "/verify-account"
                    ? "Trouble Logging In?"
                    : location.pathname === "/sign-up" && steps === 1
                    ? " Join Our Growing Community "
                    : location.pathname === "/sign-up" && steps === 2
                    ? " Secure your account access "
                    : location.pathname === "/sign-up" && steps === 3
                    ? " Keep Your Account Secure"
                    : location.pathname === "/sign-up" && steps === 4
                    ? " Company Information "
                    : location.pathname === "/"
                    ? "Access Your Account"
                    : "Trouble Logging In?"}
                </p>
                <p
                  className={`font-medium text-base leading-[24px] text-[#FFFFFF] py-1  ${
                    location.pathname === "/reset-password"
                      ? "max-w-[100%]"
                      : "max-w-[75%]"
                  }`}
                >
                  {location.pathname === "/verify-account"
                    ? "No worries! Let’s get you back into your account."
                    : location.pathname === "/sign-up" && steps === 1
                    ? " Sign up and unlock a world of features and possibilities. "
                    : location.pathname === "/sign-up" && steps === 2
                    ? " We’ve sent a verification code to your email. Please enter the code to verify your account "
                    : location.pathname === "/sign-up" && steps === 3
                    ? "Your password is the key to your account. Please enter it carefully"
                    : location.pathname === "/sign-up" && steps === 4
                    ? "Let’s customize your workspace to fit your company’s needs"
                    : location.pathname === "/"
                    ? "Log in to continue enjoying our services."
                    : "No worries! Let’s get you back into your account. "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
