import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  insightsByLocation,
  insightsConversation,
  insightsGraph,
} from "./insightsAction";

export type insightPayloadType = {
  id: number;
  name: string;
  description: string;
  total_call_volume: number;
  subtopics: {
    id: number;
    name: string;
    total_call_volume: number;
  }[];
};

export type graphType = {
  month: string;
  count: number;
};

export type summaryType = {
  farmer: {
    name: string;
    phone_number: string;
    id: number;
  };
  snippet: string;
  timestamp: {
    date: string;
    time: string;
  };
  conversation_details: {
    sender: string;
    audio_url: string;
    timestamp: string;
    farmer_question?: string;
    answer?: string;
  }[];
};

type InsightsState = {
  loading: boolean;
  insight: {
    id: number;
    name: string;
    description: string;
    total_call_volume: number;
    subtopics: {
      id: number;
      name: string;
      total_call_volume: number;
    }[];
  };
  graphData: {
    total_calls: number;
    current_month: {
      count: number;
      month: string;
      month_over_month_change: number;
    };
    monthly_breakdown: {
      data: {
        month: string;
        count: number;
      }[];
    };
  };
  graph: {
    month: string;
    count: number;
  }[];
  conversationSummary: summaryType[];
  conversationSummaryLoading: boolean;
  conversationSummaryTotal: number;
  insightsByLocationLoading: boolean;
  insightsByLocationTotal: number;
  insightsByLocationData: {
    location: string;
    call_volume: number;
    topic_id: number;
    top_insight_topic: string;
  }[];
};

const initialState: InsightsState = {
  loading: false,
  insight: {
    id: 0,
    name: "",
    description: "",
    total_call_volume: 0,
    subtopics: [],
  },
  graphData: {
    total_calls: 0,
    current_month: {
      count: 0,
      month: "",
      month_over_month_change: 0,
    },
    monthly_breakdown: {
      data: [
        {
          month: "",
          count: 0,
        },
      ],
    },
  },
  graph: [
    {
      month: "",
      count: 0,
    },
  ],
  conversationSummary: [
    {
      farmer: {
        id: 0,
        name: "",
        phone_number: "",
      },
      snippet: "",
      timestamp: {
        date: "",
        time: "",
      },
      conversation_details: [
        {
          sender: "",
          audio_url: "",
          timestamp: "",
          farmer_question: "",
        },
      ],
    },
  ],
  insightsByLocationTotal: 0,
  insightsByLocationLoading: false,
  conversationSummaryLoading: false,
  conversationSummaryTotal: 0,
  insightsByLocationData: [],
};

// Configure Redux Persist
const insightsPersistConfig = {
  key: "insights",
  storage,
  blacklist: ["loading"],
  // Add any additional configuration options as needed
};

export const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },

    handleSaveInsightDetail: (
      state,
      action: PayloadAction<insightPayloadType>
    ) => {
      state.insight = action.payload;
    },
  },

  extraReducers: (builder) => {
    // insights graph
    builder.addCase(insightsGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      insightsGraph.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload;

        state.graphData = payloadData;

        const formatMonth = (monthString: string): string => {
          const [year, month] = monthString.split("-");
          const monthMap: { [key: string]: string } = {
            January: "Jan",
            February: "Feb",
            March: "Mar",
            April: "Apr",
            May: "May",
            June: "Jun",
            July: "Jul",
            August: "Aug",
            September: "Sep",
            October: "Oct",
            November: "Nov",
            December: "Dec",
          };
          return `${monthMap[month]}(${year})`;
        };

        const formattedData: graphType[] =
          payloadData.monthly_breakdown?.data?.map((item: any) => ({
            month: formatMonth(item.month),
            count: item.count,
          }));

        state.graph = formattedData;
      }
    );
    builder.addCase(
      insightsGraph.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // insights convo summary
    builder.addCase(insightsConversation.pending, (state) => {
      state.conversationSummaryLoading = true;
      state.conversationSummary = [];
    });
    builder.addCase(
      insightsConversation.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.conversationSummaryLoading = false;
        const payloadData = action.payload;
        state.conversationSummary = payloadData?.results;
        state.conversationSummaryTotal = payloadData.count;
      }
    );
    builder.addCase(
      insightsConversation.rejected,
      (state, action: PayloadAction<any>) => {
        state.conversationSummaryLoading = false;
        state.conversationSummary = [];
      }
    );

    // insights by location
    builder.addCase(insightsByLocation.pending, (state) => {
      state.insightsByLocationLoading = true;
    });
    builder.addCase(
      insightsByLocation.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.insightsByLocationLoading = false;
        const payloadData = action.payload;
        state.insightsByLocationData = payloadData?.data;
        state.insightsByLocationTotal = payloadData.count;
      }
    );
    builder.addCase(
      insightsByLocation.rejected,
      (state, action: PayloadAction<any>) => {
        state.insightsByLocationLoading = false;
      }
    );
  },
});

const insightsReducer = persistReducer(
  insightsPersistConfig,
  insightsSlice.reducer
);
export const { logOut, handleSaveInsightDetail } = insightsSlice.actions;

export default insightsReducer;
