import React from "react";
import close_icon from "../../assets/clear.svg";
import { CSSTransition } from "react-transition-group";
import "./modal.css";

type LayoutProps = {
  title: string | React.ReactNode;
  subtitle: string;
  isOpen: boolean;
  onClose: () => void;
  allowOutsideClose: boolean; // Changed prop name for clarity
  children: React.ReactNode;
  width?: string; // Optional width for the modal content
};

const ModalComponent = ({
  onClose,
  isOpen,
  title,
  subtitle,
  children,
  width,
  allowOutsideClose,
}: LayoutProps) => {
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (allowOutsideClose && e.target === e.currentTarget) {
      onClose(); // Close the modal if outside click is allowed
    }
  };

  return (
    <>
      <CSSTransition
        in={isOpen}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
      >
        <div
          className="modal 
            overflow-y-auto remove-scroll overflow-x-hidden bg-[#344335]
            bg-opacity-50 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full
            opacity-0 transition-all duration-[0.3s]"
          onClick={handleOutsideClick} // Use the handler function here
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`relative p-8 w-full ${
              width ? width : "max-w-xl"
            } h-full md:h-auto mx-auto  modal-content translate-y-[-200px] transition-all duration-[0.3s]`}
          >
            {/* modal content */}
            <div className="relative bg-white rounded-lg py-6 px-8">
              {/* modal header */}
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-base font-semibold text-[#344335]">
                    {title}
                  </h3>
                  <p className="text-[#96A397] text-xs font-medium mt-1">
                    {subtitle}
                  </p>
                </div>

                <div className="cursor-pointer" onClick={onClose}>
                  <img src={close_icon} alt="close icon" />
                </div>
              </div>

              {/* modal body */}
              <div>{children}</div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default ModalComponent;
