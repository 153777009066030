import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Link } from "react-router-dom";
import { handleEmail, handleSteps } from "../../../redux/Auth/Auth";
import { registerUser } from "../../../redux/Auth/authAction";

const StepOne = () => {
  const dispatch = useAppDispatch();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { steps } = useAppSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workMail, setWorkMail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignUp = async () => {
    dispatch(handleEmail(workMail));
    setLoading(true);
    const credentials = {
      first_name: firstName,
      last_name: lastName,
      email: workMail,
    };
    const res = await dispatch(registerUser(credentials));
    if (res.type !== "registerUser/rejected") {
      dispatch(handleSteps(2));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <div>
      {" "}
      <>
        <div className="">
          <div className="flex flex-col  justify-end items-end">
            <p className="text-[#3E473F] text-sm font-semibold leading-[18px] pb-[24px]">
              {steps}/4
            </p>
          </div>
          <p className="text-[#181B19] text-[30px] font-semibold leading-[36px]">
            Create your account
          </p>
          <p className="text-disabled text-sm font-normal leading-5 pb-[32px] pt-1">
            Create your account to gain access to your farmers
          </p>

          <div className="flex gap-[20px] pb-5">
            <div className="w-1/2">
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                First Name
              </p>
              <input
                type="text"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                className="rounded-lg border border-[#96A397] focus:border-primary focus:shadow-1xl outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-medium leading-[18px]"
                placeholder="First Name"
              />
            </div>
            <div className="w-1/2">
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                Last Name
              </p>
              <input
                type="text"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                className="rounded-lg border border-[#96A397] focus:border-primary focus:shadow-1xl outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-medium leading-[18px]"
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className="flex gap-[20px] pb-6">
            <div className="w-full">
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                Work Email
              </p>
              <input
                type="text"
                value={workMail}
                onChange={(event) => setWorkMail(event.target.value)}
                className="rounded-lg border w-full border-[#96A397] focus:border-primary focus:shadow-1xl outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-medium leading-[18px]"
                placeholder="name@company.com"
              />
            </div>
          </div>

          <button
            onClick={handleSignUp}
            disabled={
              !loading && firstName && lastName && emailRegex.test(workMail)
                ? false
                : true
            }
            className="outline-none mb-[32px] w-full px-[32px] py-[16px] rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium leading-[18px]"
          >
            {loading ? "Loading..." : "Sign Up"}
          </button>

          <Link to="/">
            <div className="flex justify-center items-center cursor-pointer">
              {" "}
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px]">
                Already have an account?{" "}
                <span className="text-primary">Log in</span>
              </p>
            </div>
          </Link>
        </div>
      </>
    </div>
  );
};

export default StepOne;
