import React, { useState } from "react";
import GoBack from "../../components/GoBack";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { userForgotPassword } from "../../redux/Auth/authAction";

const ForgotPassword = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.auth);
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    dispatch(
      userForgotPassword({
        email: email,
      })
    );
  };

  return (
    <>
      <div className="w-full md:w-[383px]">
        <div className="flex w-full justify-between items-center pb-[24px]">
          <GoBack handleClick={() => navigate("/")} />
        </div>
        <p className="text-[#181B19] text-3xl font-semibold">Forgot password</p>
        <p className="text-[#5F6D60] text-sm mt-3 max-w-xs">
          Provide the email address associated with your account.
        </p>

        <div className="mt-8">
          <div className="w-full">
            <p className="text-[#344335] text-sm font-medium pb-1">
              Work Email
            </p>
            <div className="relative">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] bg-transparent text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                placeholder="name@company.com"
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <button
            disabled={!loading && emailRegex.test(email) ? false : true}
            onClick={handleSubmit}
            className="outline-none w-full px-8 py-4 rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium"
          >
            {loading ? "Loading..." : "   Send Password Reset Link"}
          </button>
        </div>

        <div className="flex gap-1 mt-5 justify-center items-center">
          <p className="text-[#5F6D60] text-sm font-medium">
            Remember Password?
          </p>
          <Link to="/" className="text-primary font-semibold text-sm">
            Sign In
          </Link>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
