import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard/dashboard";
import SignIn from "../pages/Auth/sign-in";
import SignUp from "../pages/Auth/sign-up";
import ResetPassword from "../pages/Auth/reset-password";
import AuthLayout from "../components/AuthLayout";
import VerifyAccount from "../pages/Auth/verify-account";
import ForgotPassword from "../pages/Auth/forgot-password";
import Sidebar from "../components/Sidebar/Sidebar";
import Farmers from "../pages/Farmers/farmers";
import FarmerProfile from "../pages/Farmers/farmer-profile";
import SettingsPage from "../pages/Settings";
import ProtectedRoute from "../components/PrivateRoutes/userRoute";
import CallInsights from "../pages/CallInsights/CallInsights";
import CallInsightCategory from "../pages/CallInsights/CallInsightCategory";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/"
        element={
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        }
      />
      <Route
        path="/sign-up"
        element={
          <AuthLayout>
            <SignUp />
          </AuthLayout>
        }
      />
      '{" "}
      <Route
        path="/verify-account"
        element={
          <AuthLayout>
            <VerifyAccount />
          </AuthLayout>
        }
      />
      '
      <Route
        path="/forgot-password"
        element={
          <AuthLayout>
            <ForgotPassword />
          </AuthLayout>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthLayout>
            <ResetPassword />
          </AuthLayout>
        }
      />
      <Route
        path="/overview"
        element={
          <ProtectedRoute>
            <Sidebar title="Overview">
              <Dashboard />
            </Sidebar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/farmers"
        element={
          <ProtectedRoute>
            <Sidebar title="Farmers">
              <Farmers />
            </Sidebar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/farmer-profile/:id"
        element={
          <ProtectedRoute>
            <Sidebar title="Farmers Profile">
              <FarmerProfile />
            </Sidebar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Sidebar title="Settings">
              <SettingsPage />
            </Sidebar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/call-insights"
        element={
          <ProtectedRoute>
            <Sidebar title="Call Insights">
              <CallInsights />
            </Sidebar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/call-insights/:category"
        element={
          <ProtectedRoute>
            <Sidebar title="">
              <CallInsightCategory />
            </Sidebar>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;
