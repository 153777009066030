"use client";
import React, { useState } from 'react';

interface TabProps {
  title: string;
  content?: string;
}

interface TabsWithHeaderProps {
  tabs: TabProps[];
  onTabChange: (index: number) => void; // Callback for tab change
}

const Tabs: React.FC<TabsWithHeaderProps> = ({ tabs, onTabChange }) => {
  const [activeTab, setActiveTab] = useState<number>(0); // Store active tab index

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    onTabChange(index); // Notify parent about tab change
  };

  return (
    <div>
      <ul className="flex gap-4 items-center">
        {tabs.map((tab: TabProps, index: number) => (  
          <li
            key={index}
            className={`text-xs py-1 font-medium cursor-pointer ${
              activeTab === index ? 'text-primary border-b-2 border-primary' : 'text-[#96A397]'
            }`}
            onClick={() => handleTabChange(index)} // Update active tab
          >
            {tab.title}
          </li>
        ))}
      </ul>

      <div className="mt-4">
        {tabs[activeTab] && <div>{tabs[activeTab].content}</div>}
      </div>
    </div>
  );
};

export default Tabs;
