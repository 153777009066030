import StepOne from "../../containers/auth/sign-up/step-one";
import { useAppSelector } from "../../redux/store";
import StepTwo from "../../containers/auth/sign-up/step-two";
import StepThree from "../../containers/auth/sign-up/step-three";
import StepFour from "../../containers/auth/sign-up/step-four";

const SignUp = () => {
  const { steps } = useAppSelector((state) => state.auth);
  return (
    <>
      {steps === 1 ? (
        <StepOne />
      ) : steps === 2 ? (
        <StepTwo />
      ) : steps === 3 ? (
        <StepThree />
      ) : steps === 4 ? (
        <StepFour />
      ) : null}
    </>
  );
};

export default SignUp;
